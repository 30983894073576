import { Scene, PerspectiveCamera, WebGLRenderer, TextureLoader, MeshBasicMaterial, Mesh, SphereGeometry, LineBasicMaterial, EllipseCurve, BufferGeometry, Line, Vector2, Vector3 } from 'three';

import * as THREE from 'three'; // Import the entire Three.js library

import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS2DObject, CSS2DRenderer } from './CSS2DRenderer.js';
import sunImage from '../assets/sun2.jpg';
import mercuryImage from '../assets/mercury1.jpg';
import venusImage from '../assets/venus1.jpg';
import earthImage from '../assets/earth1.jpg';
import moonImage from '../assets/moon2.jpg'
import marsImage from '../assets/mars1.jpg';
import jupiterImage from '../assets/jupiter1.jpg';
import saturnImage from '../assets/saturn1.jpg';
import uranusImage from '../assets/uranus1.jpg';
import neptuneImage from '../assets/neptune1.jpg';
import plutoImage from '../assets/pluto1.jpg';
import rahuImage from '../assets/saturn2.jpg';
import ketuImage from '../assets/saturn2.jpg';
import pxImage from '../assets/stars_milky_way.jpg';
import helvetiker from '../fonts/helvetiker_regular.typeface.json'
import axios from 'axios';

const loader = new TextureLoader();

let planetPositionData = null;
const scene = new Scene();
export const ASTRO_UID = '622454';
export const ASTRO_API_KEY = '0742e2a6b57b2f43f00e3323e7325cf7';

const placeInput = document.getElementById("placeInput");
const suggestionsContainer = document.getElementById("suggestionsContainer");
const liveIcon = document.getElementById("liveIcon");
const select_tag = document.getElementById("select_tag");
const options = document.getElementById("options");
let active = 'Live';
let timeoutId;

select_tag.addEventListener('click', function () {
    console.log('................', options.style.display);
    options.style.display = options.style.display == 'none' ? 'block' : 'none';
});

const image_live_status = document.getElementById('live_status');
const image_solar_status = document.getElementById('solar_status');
const image_form_status = document.getElementById('form_status');
const form_close = document.getElementById('form_close');
const heading_text = document.getElementById('heading_text');

image_live_status.addEventListener('click', function () {
    active = 'Live';
    heading_text.innerText = 'Live Chart'
    updateImageSrc();
    document.getElementById("myForm").style.display = "none";
    liveIcon.style.display = "block";
    planetPositionData = null;
    adjustLabelHeights();
    setupSolarSystem();
    setupZodiacWithPlanets();
    const starTexture = loader.load(pxImage, function () {
        const starSphere = new THREE.Mesh(
            new THREE.SphereGeometry(15000, 32, 32),
            new THREE.MeshBasicMaterial({
                map: starTexture,
                side: THREE.BackSide
            })
        );
        scene.add(starSphere);

    }, undefined, function (error) {
        console.error('Error loading texture:', error);
    });
});

image_solar_status.addEventListener('click', function () {
    active = 'Solar';
    heading_text.innerText = 'Solar System'
    updateImageSrc();
    document.getElementById("myForm").style.display = "none";
});

image_form_status.addEventListener('click', function () {
    heading_text.innerText = 'My Chart'
    active = 'Form';
    updateImageSrc();
    const storedFormData = localStorage.getItem('formData');
    const dateInput = document.getElementById("dateInput");
    const timeInput = document.getElementById("timeInput");
    // const timezone = document.getElementById("timezoneInput").value;
    const latitude = document.getElementById("latitudeInput");
    const longitude = document.getElementById("longitudeInput");
    const place = document.getElementById("placeInput");
    const name_input = document.getElementById("name_input");

    if (storedFormData) {
        const formData = JSON.parse(storedFormData);
        name_input.value = formData?.name ? formData?.name : '';
        dateInput.value = formData?.dateInput ? formData?.dateInput : '';
        timeInput.value = formData?.timeInput ? formData?.timeInput : '';
        // timezone.value = formData.timezone;
        latitude.value = formData?.latitude ? formData?.latitude : '';
        longitude.value = formData?.longitude ? formData?.longitude : '';
        place.value = formData?.place ? formData?.place : '';
    }
});


const updateImageSrc = () => {
    if (active === 'Live') {
        heading_text.innerText = 'Live Chart'
        image_live_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/434ed66f-3b6c-436b-6032-6f916778c400/public';
    } else {
        image_live_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/5e8ca75f-42b9-47cf-afe6-a2c83020ab00/public';
    }
    if (active === 'Solar') {
        heading_text.innerText = 'Solar System'
        image_solar_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/ee47d18d-a680-45ad-0c92-382703136200/public'
    } else {
        image_solar_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/1d3e5448-ec25-47b5-10cf-3421acbf8300/public'
    }
    if (active === 'Form') {
        heading_text.innerText = 'My Chart'
        image_form_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/93d1c09e-2f2f-4ad0-5045-292eeab53d00/public';
        openForm();
    } else {
        image_form_status.src = 'https://imagedelivery.net/lc9DY1zuaaOZg0J1jm7SLw/b4c73361-cc1b-4f1e-2678-8dd5d8f32800/public'
    }
};

form_close.addEventListener('click', function () {
    document.getElementById("myForm").style.display = "none";
    active = 'Live';
    updateImageSrc();
})

placeInput.addEventListener("input", async (event) => {
    clearTimeout(timeoutId); // Clear any existing timeout
    suggestionsContainer.innerHTML = ""; // Clear previous suggestions

    const searchTerm = event.target.value;
    if (searchTerm.length >= 3) {
        // Wait for 2 seconds before making the API call
        timeoutId = setTimeout(async () => {
            const places = await fetchPlaces(searchTerm);
            places.forEach(place => {
                const suggestion = document.createElement("div");
                suggestion.textContent = place.place;
                suggestion.dataset.place = place.place; // Store place name in dataset
                suggestion.dataset.latitude = place.latitude; // Store latitude in dataset
                suggestion.dataset.longitude = place.longitude; // Store longitude in dataset
                suggestion.classList.add("suggestion");
                suggestionsContainer.appendChild(suggestion);
            });
        }, 1000);
    }
});

// Function to handle suggestion click
function handleSuggestionClick(place, latitude, longitude) {
    // Set the input text to the selected place
    document.getElementById("placeInput").value = place;

    // Set latitude and longitude in their respective input fields
    document.getElementById("latitudeInput").value = latitude;
    document.getElementById("longitudeInput").value = longitude;

    // Hide the suggestion container
    document.getElementById("suggestionsContainer").style.display = "none";
}

suggestionsContainer.addEventListener("click", function (event) {
    if (event.target.classList.contains("suggestion")) {
        console.log('......', event.target?.dataset)
        const place = event.target.dataset.place;
        const latitude = event.target.dataset.latitude;
        const longitude = event.target.dataset.longitude;
        handleSuggestionClick(place, latitude, longitude);
    }
});

async function fetchPlaces(searchTerm) {
    const reqData = {
        country: 'India',
        name: searchTerm
    };
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa(ASTRO_UID + ':' + ASTRO_API_KEY)
    };
    try {
        const response = await axios.post(
            'https://geo.astrologyapi.com/places/',
            JSON.stringify(reqData),
            { headers: headers }
        );
        return response.data;
    } catch (error) {
        console.error('Error fetching places:', error);
        return [];
    }
}


document.getElementById("call_planets_btn").addEventListener("click", onSubmit);

function showCustomAlert(message) {
    const modal = document.getElementById("customAlert");
    const alertMessage = document.getElementById("alertMessage");
    alertMessage.textContent = message;
    modal.style.display = "block";
    const closeButton = document.querySelector(".close");
    closeButton.onclick = function () {
        modal.style.display = "none";
    };
    setTimeout(function () {
        modal.style.display = "none";
    }, 1000);
}

async function onSubmit() {
    const dateInput = document.getElementById("dateInput");
    const selectedDate = dateInput.value; // Format: yyyy-mm-dd
    const [year, month, day] = selectedDate.split('-');
    const timeInput = document.getElementById("timeInput");
    const selectedTime = timeInput.value; // Format: hh:mm
    const [hour, minute] = selectedTime.split(':');
    const second = "00";
    const timezone = document.getElementById("timezoneInput").value;
    const latitude = document.getElementById("latitudeInput").value;
    const longitude = document.getElementById("longitudeInput").value;
    const name_input = document.getElementById("name_input").value;
    const place = document.getElementById("placeInput").value;

    const genderRadios = document.querySelectorAll('input[name="gender"]');

    let selectedGender = '';
    genderRadios.forEach(radio => {
        radio.addEventListener('change', () => {
            if (radio.checked) {
                selectedGender = radio.value;
                console.log('Selected gender:', selectedGender);
            }
        });
    });


    const fData = {
        dateInput: dateInput.value,
        timeInput: timeInput.value,
        timezone: timezone,
        latitude: latitude,
        longitude: longitude,
        name: name_input,
        gender: selectedGender,
        place: place
    };
    localStorage.setItem('formData', JSON.stringify(fData));

    if (!dateInput || !timeInput || !latitude || !longitude || !timezone || !name_input) {
        showCustomAlert("Please fill in all required fields.");
    }
    else {
        const formData = {
            day: Number(day),
            month: Number(month),
            year: Number(year),
            hour: Number(hour),
            minute: Number(minute),
            second: Number(second),
            timezone: timezone,
            latitude: Number(latitude),
            longitude: Number(longitude)
        };

        if (formData) {
            planetPositionData = formData;
            adjustLabelHeights();
            setupSolarSystem();
            setupZodiacWithPlanets();
            const starTexture = loader.load(pxImage, function () {
                const starSphere = new THREE.Mesh(
                    new THREE.SphereGeometry(15000, 32, 32),
                    new THREE.MeshBasicMaterial({
                        map: starTexture,
                        side: THREE.BackSide
                    })
                );
                scene.add(starSphere);

            }, undefined, function (error) {
                console.error('Error loading texture:', error);
            });

        }
        liveIcon.style.display = "none";
        // switch_to_live.style.display = 'block';
        showCustomAlert("Submitted Successfully");
        document.getElementById("myForm").style.display = "none";
        // updateImageSrc();
    }
}

async function fetchPlanetDataFromAPI(formData) {
    // updateImageSrc();
    const currentDate = new Date(); // Get the current date and time
    const currentDateTime = {
        day: currentDate.getDate(),
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
        hour: currentDate.getHours(),
        minute: currentDate.getMinutes(),
        second: currentDate.getSeconds(),
        timezone: "Asia/Kolkata", // Set timezone to "Asia/Kolkata"
        latitude: 0,
        longitude: 0
    };
    console.log("MY FORM DATA", formData);
    const response = await fetch('https://backend.astrochalit.com/planets', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData ? formData : currentDateTime) // Send current date, time, and timezone in the request body
    });

    if (!response.ok) {
        throw new Error('Network response was not ok.......');
    }

    const responseData = await response.json();
    // Check if Rahu and Ketu data exists in the response
    const rahuData = responseData.planetDetails.find(planet => (planet.name.charAt(0).toUpperCase() + planet.name.slice(1).toLowerCase()) === 'rahu');
    const ketuData = responseData.planetDetails.find(planet => (planet.name.charAt(0).toUpperCase() + planet.name.slice(1).toLowerCase()) === 'ketu');
    const ascData = responseData.planetDetails.find(planet => (planet.name.charAt(0).toUpperCase() + planet.name.slice(1).toLowerCase()) === 'Ascendant');

    // Add Rahu and Ketu data if available
    if (rahuData) {
        // Set position for Rahu
        celestialBodies.Rahu.mesh.position.set(rahuData.positionX, 0, rahuData.positionZ);
        // Apply grey color directly to Rahu
        celestialBodies.Rahu.mesh.material.color.set(0x999999); // Grey color
    }

    if (ketuData) {
        // Set position for Ketu
        celestialBodies.Ketu.mesh.position.set(ketuData.positionX, 0, ketuData.positionZ);
        // Apply grey color directly to Ketu
        celestialBodies.Ketu.mesh.material.color.set(0x999999); // Grey color
    }

    if (ascData) {
        // Set position for Ketu
        celestialBodies.Ascendant.mesh.position.set(ascData.positionX, 0, ascData.positionZ);
        // Apply grey color directly to Ketu
        celestialBodies.Ascendant.mesh.material.color.set(0x999999); // Grey color
    }

    return responseData;
}


// Define Zodiac Sign Positions in degrees
const zodiacDegrees = {
    Aries: 0,
    Taurus: 30,
    Gemini: 60,
    Cancer: 90,
    Leo: 120,
    Virgo: 150,
    Libra: 180,
    Scorpio: 210,
    Sagittarius: 240,
    Capricorn: 270,
    Aquarius: 300,
    Pisces: 330
};

const nakshatraData = {
    'Ashwini': [0, 13.3333, 'Ketu'],
    'Bharani': [13.3333, 26.6667, 'Venus'],
    'Krittika': [26.6667, 40, 'Sun'],
    'Rohini': [40, 53.3333, 'Moon'],
    'Mrigas..': [53.3333, 66.6667, 'Mars'],
    'Ardra': [66.6667, 80, 'Rahu'],
    'Punarvasu': [80, 93.3333, 'Jupiter'],
    'Pushya': [93.3333, 106.6667, 'Saturn'],
    'Ashlesha': [106.6667, 120, 'Mercury'],
    'Magha': [120, 133.3333, 'Ketu'],
    'P.Phalguni': [133.3333, 146.6667, 'Venus'],
    'U.Phalguni': [146.6667, 160, 'Sun'],
    'Hasta': [160, 173.3333, 'Moon'],
    'Chitra': [173.3333, 186.6667, 'Mars'],
    'Swati': [186.6667, 200, 'Rahu'],
    'Vishakha': [200, 213.3333, 'Jupiter'],
    'Anuradha': [213.3333, 226.6667, 'Saturn'],
    'Jyeshtha': [226.6667, 240, 'Mercury'],
    'Mula': [240, 253.3333, 'Ketu'],
    'P.Ashadha': [253.3333, 266.6667, 'Venus'],
    'U.Ashadha': [266.6667, 280, 'Sun'],
    'Shravana': [280, 293.3333, 'Moon'],
    'Dhanishta': [293.3333, 306.6667, 'Mars'],
    'Satabisha': [306.6667, 320, 'Rahu'],
    'P.Bhadra..': [320, 333.3333, 'Jupiter'],
    'U.Bhadra..': [333.3333, 346.6667, 'Saturn'],
    'Revati': [346.6667, 360, 'Mercury']
};


// Convert degrees to radians
function degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
}

// Basic setup
// const scene = new Scene();
// scene.background = new Color(0x000022);  // A deep blue color
const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

const renderer = new WebGLRenderer({ antialias: true });
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

const labelRenderer = new CSS2DRenderer();
labelRenderer.setSize(window.innerWidth, window.innerHeight);
labelRenderer.domElement.style.pointerEvents = 'none';
labelRenderer.domElement.style.position = 'absolute';
labelRenderer.domElement.style.top = '0px';
document.body.appendChild(labelRenderer.domElement);

// Adjust the camera's far clipping plane to accommodate the larger starry background
camera.far = 100000; // Adjust this value as per your requirement
camera.near = 1;
camera.updateProjectionMatrix(); // Necessary after changing camera properties

const controls = new OrbitControls(camera, renderer.domElement);
controls.enabled = true;
controls.minDistance = 1;  // The minimum zoom level (or closest approach)
controls.maxDistance = 10000;  // The maximum zoom level (or farthest distance)
controls.enablePan = true; //If you want to disable panning (moving the camera up/down or left/right without rotating)
// If you want to restrict the vertical rotation (so you can't flip the camera upside-down for instance)
controls.minPolarAngle = 0;  // radians
controls.maxPolarAngle = Math.PI;  // radians
//If you want smoother transitions when moving the camera
controls.enableDamping = true;
controls.dampingFactor = 0.05;
controls.rotateSpeed = 1.0;
controls.zoomSpeed = 1.2;
controls.panSpeed = 0.8;

// Updated Celestial Body (Planet) Class
class CelestialBody {
    constructor(name, insights, imagePath, size, weight, speed, distanceFromSun) {
        this.name = name;
        this.insights = insights;
        this.size = size; // Diameter of the planet
        this.weight = weight; // Mass of the planet
        this.speed = speed; // Orbital speed
        this.distanceFromSun = distanceFromSun; // Average distance from the sun

        const texture = loader.load(imagePath);
        const material = new MeshBasicMaterial({ map: texture });
        this.mesh = new Mesh(new SphereGeometry(1, 32, 32), material);

        this.mesh.userData = {
            name: this.name,
            insights: this.insights,
            size: this.size,
            weight: this.weight,
            speed: this.speed,
            distanceFromSun: this.distanceFromSun
        };
    }
}

const celestialBodies = {
    Sun: new CelestialBody("Sun", "The Sun is the star at the center of the Solar System.", sunImage, "1.39 million km", "1.989 × 10^30 kg", "Static", "Center of Solar System"),
    Mercury: new CelestialBody("Mercury", "Mercury is the smallest and innermost planet in the Solar System.", mercuryImage, "4,880 km", "3.3 × 10^23 kg", "47.87 km/s", "57.91 million km"),
    Venus: new CelestialBody("Venus", "Venus is the second planet from the Sun.", venusImage, "12,104 km", "4.87 × 10^24 kg", "35.02 km/s", "108.2 million km"),
    Earth: new CelestialBody("Earth", "Earth is the third planet from the Sun and the only astronomical object known to harbor life.", earthImage, "12,742 km", "5.972 × 10^24 kg", "29.78 km/s", "149.6 million km"),
    Moon: new CelestialBody("Moon", "The Moon is Earth's only natural satellite.", moonImage, "3,474 km", "7.35 × 10^22 kg", "1.022 km/s", "384,400 km"),
    Mars: new CelestialBody("Mars", "Mars is the fourth planet from the Sun and the second-smallest planet in the Solar System.", marsImage, "6,779 km", "6.39 × 10^23 kg", "24.077 km/s", "227.9 million km"),
    Jupiter: new CelestialBody("Jupiter", "Jupiter is the fifth planet from the Sun and the largest in the Solar System.", jupiterImage, "139,820 km", "1.898 × 10^27 kg", "13.07 km/s", "778.5 million km"),
    Saturn: new CelestialBody("Saturn", "Saturn is the sixth planet from the Sun and the second-largest in the Solar System.", saturnImage, "116,460 km", "5.68 × 10^26 kg", "9.68 km/s", "1.42 billion km"),
    Uranus: new CelestialBody("Uranus", "Uranus is the seventh planet from the Sun.", uranusImage, "50,724 km", "8.68 × 10^25 kg", "6.8 km/s", "2.87 billion km"),
    Neptune: new CelestialBody("Neptune", "Neptune is the eighth and farthest known planet from the Sun in the Solar System.", neptuneImage, "49,244 km", "1.02 × 10^26 kg", "5.43 km/s", "4.5 billion km"),
    Pluto: new CelestialBody("Pluto", "Pluto is a dwarf planet in the Kuiper belt.", plutoImage, "2,377 km", "1.31 × 10^22 kg", "4.67 km/s", "5.91 billion km"),
    Rahu: new CelestialBody("Rahu", "Rahu is a shadow planet", rahuImage, "Rahu size", "Rahu weight", "Rahu speed", "Rahu distance from the Sun"),
    Ketu: new CelestialBody("Ketu", "Ketu is a shadow planet", ketuImage, "Ketu size", "Ketu weight", "Ketu speed", "Ketu distance from the Sun"),
    Ascendant: new CelestialBody("Ascendant", "The Ascendant, or rising sign, is the zodiacal sign and degree that is ascending on the eastern horizon at the specific time and location of an event.", rahuImage, "Ascendant size", "Ascendant weight", "Ascendant speed", "Ascendant distance from the Sun"),

};


// Modifying the sizes based on provided dimensions
celestialBodies.Sun.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Mercury.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Venus.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Earth.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Moon.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Mars.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Jupiter.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Saturn.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Uranus.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Neptune.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Pluto.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Rahu.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Ketu.mesh.scale.set(10.78, 10.78, 10.78);
celestialBodies.Ascendant.mesh.scale.set(10.78, 10.78, 10.78);


const celestialMeshes = [];
// Modify planet creation by adding the planet name
for (let body in celestialBodies) {
    const mesh = celestialBodies[body].mesh;
    scene.add(mesh);
    celestialMeshes.push(mesh);
}

let mouse = new Vector2();

// Assuming loader and scene are already defined
const starTexture = loader.load(pxImage, function () {
    const starSphere = new THREE.Mesh(
        new THREE.SphereGeometry(15000, 32, 32),
        new THREE.MeshBasicMaterial({
            map: starTexture,
            side: THREE.BackSide
        })
    );
    scene.add(starSphere);

}, undefined, function (error) {
    console.error('Error loading texture:', error);
});


window.addEventListener('mousemove', (event) => {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
});


window.addEventListener('resize', function () {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    labelRenderer.setSize(window.innerWidth, window.innerHeight);
});


const CAMERA_DISTANCE = 0;  // The constant distance from the planet when the camera focuses on it.
let currentTarget = null; // Initially, no planet is in focus
let focusedPlanet = null;


camera.position.z = 100; // Adjusted to see more of the solar system

// Define a rotation speed for each celestial body
const rotationSpeeds = {
    earth: 0

};

// Define angles for each celestial body
const angles = {
    mercury: 0,
    venus: 1,
    earth: 2,
    moon: 0,  // Relative to Earth
    mars: 3,
    jupiter: 4,
    saturn: 5,
    uranus: 6,
    neptune: 7,
    pluto: 8
};

// Initialize an object to keep track of created labels
let labelMeshes = {};  // Change from const to let

function createLabel(name, parentMesh) {
    const fontLoader = new FontLoader();

    // Load the font
    fontLoader.load(helvetiker, function (font) {
        // Create the text geometry
        const textGeometry = new TextGeometry(name, {
            font: font,
            size: 8, // Adjust the size to match Nakshatra names
            height: 0.5, // Adjust the height/thickness of the text
        });

        // Create the text material
        const textMaterial = new MeshBasicMaterial({ color: 0xffffff }); // Adjust color as needed

        // Create the text mesh
        const textMesh = new Mesh(textGeometry, textMaterial);

        // Adjust the position of the text mesh relative to the parent mesh (planet)
        // You might need to adjust the position to make sure it's visible and not overlapping with the planet mesh
        textMesh.position.set(parentMesh.position.x, parentMesh.position.y + parentMesh.geometry.parameters.radius + 1, parentMesh.position.z);

        // Add the text mesh to the scene
        scene.add(textMesh);

        // Keep a reference to the text mesh in the labelMeshes object
        labelMeshes[name] = textMesh;

    }, undefined, function (error) {
        console.error('Error loading the font:', error);
    });
}

// Updated clearLabels function
function clearLabels() {
    for (let planet in labelMeshes) {
        const label = labelMeshes[planet];
        if (label) {
            // Remove the label from its parent mesh
            label.parent.remove(label);
        }
    }

    // Clear the labelMeshes object
    labelMeshes = {};  // Reset to an empty object
}

async function setupHouseBoundaries(ascendantSign) {

    let startDegree = zodiacDegrees[ascendantSign];
    const innerBoundaryRadius = 550; // Inner radius for the boundary line
    const outerBoundaryRadius = 600; // Outer radius for the boundary line

    for (let i = 0; i < 12; i++) {
        let houseStartDegree = (startDegree + i * 30) % 360;
        let houseEndDegree = (houseStartDegree + 30) % 360;

        // Generate boundary lines
        let startAngle = degreesToRadians(houseStartDegree);
        let startPoint = new THREE.Vector3(550 * Math.cos(startAngle), 0, 550 * Math.sin(startAngle));
        let endPoint = new THREE.Vector3(600 * Math.cos(startAngle), 0, 600 * Math.sin(startAngle));
        let lineGeometry = new THREE.BufferGeometry().setFromPoints([startPoint, endPoint]);
        let lineMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });
        let line = new THREE.Line(lineGeometry, lineMaterial);
        scene.add(line);
    }

    const fontLoader = new FontLoader();

    fontLoader.load(helvetiker, function (font) {
        for (let i = 0; i < 12; i++) {
            let houseNumber = i + 1;
            let houseStartDegree = (startDegree + i * 30) % 360;
            let houseEndDegree = (houseStartDegree + 30) % 360;

            // Calculate the midpoint of the house for placing the house number
            let midDegree;
            if (houseStartDegree > houseEndDegree) { // This handles the transition from 330° to 0°
                midDegree = ((houseStartDegree + (houseEndDegree + 360)) / 2) % 360;
            } else {
                midDegree = (houseStartDegree + houseEndDegree) / 2;
            }
            let midAngle = degreesToRadians(midDegree);

            const midPosition = new THREE.Vector3(
                575 * Math.cos(midAngle),
                10, // Adjust height as needed
                575 * Math.sin(midAngle)
            );

            // Create text geometry and mesh for the house number
            const textGeometry = new TextGeometry(`House ${houseNumber}`, {
                font: font,
                size: 8,
                height: 1,
            });
            const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
            const textMesh = new THREE.Mesh(textGeometry, textMaterial);
            textMesh.position.copy(midPosition);
            textMesh.lookAt(camera.position);

            // Add the text mesh to the scene
            scene.add(textMesh);
        }
    }, undefined, function (error) {
        console.error('Error loading the font:', error);
    });
}

async function setupZodiacWithPlanets() {

    const radius = 716;
    const material = new THREE.LineBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.5 });

    const outerRadius = 716;
    const innerRadius = 600; // Adjust this value as needed

    const padaLength = 3.3333; // Each pada is 3 degrees and 20 minutes, which is approx 3.3333 degrees

    // Iterate over each nakshatra and draw pada lines
    Object.entries(nakshatraData).forEach(([nakshatra, [startDegree]]) => {
        for (let i = 1; i <= 4; i++) { // 4 padas for each nakshatra
            const padaDegree = startDegree + padaLength * i;
            const angle = degreesToRadians(padaDegree);

            // Calculate the starting and ending points for the line
            const outerPoint = new THREE.Vector3(outerRadius * Math.cos(angle), 0, outerRadius * Math.sin(angle));
            const innerPoint = new THREE.Vector3(innerRadius * Math.cos(angle), 0, innerRadius * Math.sin(angle));

            // Draw the line
            const points = [innerPoint, outerPoint];
            const geometry = new THREE.BufferGeometry().setFromPoints(points);
            const material = new THREE.LineBasicMaterial({ color: 0xffff00, transparent: true, opacity: 0.5 });
            const line = new THREE.Line(geometry, material);
            scene.add(line);
        }
    });

    // Create Zodiac Boundaries using TubeGeometry for thicker lines
    for (let degree = 0; degree < 360; degree += 30) {
        const angle = degreesToRadians(degree);
        const outerPoint = new THREE.Vector3(outerRadius * Math.cos(angle), 0, outerRadius * Math.sin(angle));
        const innerPoint = new THREE.Vector3(innerRadius * Math.cos(angle), 0, innerRadius * Math.sin(angle));

        const path = new THREE.LineCurve3(innerPoint, outerPoint); // Create a path from inner to outer point
        const geometry = new THREE.TubeGeometry(path, 1, 2, 8, false); // TubeGeometry for thicker line, adjust radius for thickness

        // Use the same color as zodiac sign names
        const material = new THREE.MeshBasicMaterial({ color: 0xffa500 });

        const tube = new THREE.Mesh(geometry, material);
        scene.add(tube);
    }

    // Add Zodiac Sign Names
    Object.keys(zodiacDegrees).forEach(zodiacSign => {
        const angle = degreesToRadians(zodiacDegrees[zodiacSign] + 15);
        const heightAdjustment = 80; // Increase this value to raise the text higher
        const textPos = new THREE.Vector3(1.1 * radius * Math.cos(angle), heightAdjustment, 1.1 * radius * Math.sin(angle));
        createText(zodiacSign, textPos, 0xffa500); // Specify color for sign names (e.g., red)
    });

    // Add Nakshatra Names
    const fontLoader = new FontLoader();
    fontLoader.load(helvetiker, function (font) {
        Object.keys(nakshatraData).forEach(nakshatra => {
            const angle = degreesToRadians(nakshatraData[nakshatra][0] + 13.3333 / 2); // Middle of the nakshatra
            const nakshatraTextRadius = 716; // Close to customOrbit1
            const textPos = new THREE.Vector3(
                nakshatraTextRadius * Math.cos(angle),
                10, // Adjust the height if needed
                nakshatraTextRadius * Math.sin(angle)
            );

            // Create text geometry and mesh for nakshatra name
            const textGeometry = new TextGeometry(nakshatra, {
                font: font,
                size: 10, // Reduced size
                height: 4,
            });
            const textMaterial = new MeshBasicMaterial({ color: 0xffffff }); // Adjust color as needed
            const textMesh = new Mesh(textGeometry, textMaterial);
            textMesh.position.copy(textPos);
            textMesh.lookAt(camera.position);
            scene.add(textMesh);
        });
    }, undefined, function (error) {
        console.error('Error loading the font:', error);
    });


    // Function to convert pada number to Roman numeral
    function toRoman(num) {
        switch (num) {
            case 1: return 'I';
            case 2: return 'II';
            case 3: return 'III';
            case 4: return 'IV';
            default: return '';
        }
    }

    // Ensure the font is loaded before proceeding
    fontLoader.load(helvetiker, function (font) {
        // Adding pada numbers once the font is successfully loaded
        Object.keys(nakshatraData).forEach(nakshatra => {
            const startDegree = nakshatraData[nakshatra][0];
            for (let pada = 1; pada <= 4; pada++) {
                const padaDegree = startDegree + (pada - 1) * 3.3333 + 1.66665; // Middle of the pada
                const angle = degreesToRadians(padaDegree);
                const padaTextRadius = 716; // Slightly inside the customOrbit1
                const textPos = new THREE.Vector3(
                    padaTextRadius * Math.cos(angle),
                    0,
                    padaTextRadius * Math.sin(angle)
                );

                // Create text geometry and mesh for pada number
                const textGeometry = new TextGeometry(toRoman(pada), {
                    font: font,
                    size: 5, // Adjust size as needed
                    height: 0.1,
                });
                const textMaterial = new MeshBasicMaterial({ color: 0xffffff });
                const textMesh = new Mesh(textGeometry, textMaterial);
                textMesh.position.copy(textPos);
                textMesh.lookAt(camera.position);
                scene.add(textMesh);
            }
        });
    }, undefined, function (error) {
        console.error('Error loading the font:', error);
    });


    function positionNakshatrasCorrectly() {
        const nakshatraRadius = 716; // Adjust as needed

        Object.entries(nakshatraData).forEach(([nakshatra, [startDegree]]) => {
            // Convert the start degree to radians
            const angleRadians = degreesToRadians(startDegree);

            // Calculate the x and z positions based on the angle and radius
            const posX = nakshatraRadius * Math.cos(angleRadians);
            const posZ = nakshatraRadius * Math.sin(angleRadians);

            // Create a marker for each Nakshatra
            const markerGeometry = new THREE.SphereGeometry(5, 32, 32); // Small sphere for visibility
            const markerMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 }); // Green color for Nakshatras
            const nakshatraMarker = new THREE.Mesh(markerGeometry, markerMaterial);

            // Set the position of the Nakshatra marker
            nakshatraMarker.position.set(posX, 0, posZ);

            // Add the marker to the scene
            scene.add(nakshatraMarker);

            // Calculate the angle from the position and convert it back to degrees
            let placedAngleRadians = Math.atan2(posZ, posX);
            if (placedAngleRadians < 0) {
                placedAngleRadians += 2 * Math.PI; // Normalize the angle
            }
            const placedDegree = THREE.MathUtils.radToDeg(placedAngleRadians);

        });
    }


    // Call this function after setting up the scene and camera
    positionNakshatrasCorrectly();

    // Define the outer radius for the Nakshatra boundaries
    const nakshatraBoundaryOuterRadius = 735;
    const nakshatraBoundaryInnerRadius = 716;  // You can adjust this as needed

    // Material for the Nakshatra boundary lines
    const nakshatraBoundaryMaterial = new THREE.LineBasicMaterial({
        color: 0xffff00,  // Green color for the Nakshatra boundaries
        transparent: true,
        opacity: 0.5
    });


    fontLoader.load(helvetiker, (font) => {
        Object.entries(nakshatraData).forEach(([nakshatra, [startDegree, endDegree, lord]]) => {
            const angle = degreesToRadians(startDegree);

            // Starting and ending points for the boundary line of each Nakshatra
            const startPoint = new THREE.Vector3(nakshatraBoundaryInnerRadius * Math.cos(angle), 0, nakshatraBoundaryInnerRadius * Math.sin(angle));
            const endPoint = new THREE.Vector3(nakshatraBoundaryOuterRadius * Math.cos(angle), 0, nakshatraBoundaryOuterRadius * Math.sin(angle));

            // Geometry and line creation for Nakshatra boundary
            const boundaryGeometry = new THREE.BufferGeometry().setFromPoints([startPoint, endPoint]);
            const nakshatraBoundaryLine = new THREE.Line(boundaryGeometry, nakshatraBoundaryMaterial);
            scene.add(nakshatraBoundaryLine);  // Add the boundary line to the scene

            // Calculate the middle angle for the Nakshatra lord name position
            const nameAngle = degreesToRadians(startDegree + (endDegree - startDegree) / 2);
            const nakshatraNameRadius = (nakshatraBoundaryInnerRadius + nakshatraBoundaryOuterRadius) / 2;  // Position the name halfway between the inner and outer boundaries

            // Position for Nakshatra lord name
            const textPosition = new THREE.Vector3(
                nakshatraNameRadius * Math.cos(nameAngle),
                0,  // Adjust the y position as needed
                nakshatraNameRadius * Math.sin(nameAngle)
            );

            // Text geometry and material for Nakshatra lord name
            const textGeometry = new TextGeometry(lord, {
                font: font,
                size: 5,  // Adjust text size as needed
                height: 1  // Adjust text height/thickness as needed
            });
            const textMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });  // Color for Nakshatra lord names, change as desired
            const textMesh = new THREE.Mesh(textGeometry, textMaterial);
            textMesh.position.copy(textPosition);
            textMesh.lookAt(new THREE.Vector3(0, 0, 0));  // Ensure the text faces the center of the scene
            scene.add(textMesh);  // Add the Nakshatra lord name to the scene
        });
    }, undefined, (error) => {
        console.error('Error loading the font:', error);
    });


    // Set Earth's position at the center
    celestialBodies.Earth.mesh.position.set(0, 0, 0);

    // Fetch Planets Data and Place Them in Zodiac Signs
    const liveData = await fetchPlanetDataFromAPI(planetPositionData);
    const radius1 = 600;

    liveData.planetDetails.forEach(planetData => {
        const planetKey = planetData.name; // Capitalize the first letter
        if (celestialBodies[planetKey]) {
            const planetDegree = planetData.fullDegree % 360; // Normalize the degree
            const angle = degreesToRadians(planetDegree);

            const positionX = radius1 * Math.cos(angle);
            const positionZ = radius1 * Math.sin(angle);

            celestialBodies[planetKey].mesh.position.set(positionX, 0, positionZ);
        }
    });

    await adjustLabelHeights();

    const ascendantDetail = liveData.planetDetails.find(detail => detail.name === "Ascendant");
    if (ascendantDetail) {
        const ascendantSign = ascendantDetail.sign;
        // Use the Ascendant sign to set up house boundaries
        setupHouseBoundaries(ascendantSign);
    } else {
        console.log("Ascendant sign not found in the API response.");
    }
}

let additionalLabelHeights = {};

async function adjustLabelHeights() {
    const liveData = await fetchPlanetDataFromAPI(planetPositionData);
    let planetGroups = []; // Holds groups of close planets

    liveData.planetDetails.forEach((planetData, index) => {
        let foundGroup = false;
        for (let group of planetGroups) {
            if (group.some(pd => Math.abs(pd.fullDegree - planetData.fullDegree) <= 5)) {
                group.push(planetData);
                foundGroup = true;
                break;
            }
        }

        if (!foundGroup) {
            planetGroups.push([planetData]);
        }
    });

    // Sort each group by degree and adjust heights
    planetGroups.forEach(group => {
        group.sort((a, b) => a.fullDegree - b.fullDegree); // Sort by degree
        for (let i = 1; i < group.length; i++) { // Start from the second item
            const planetName = group[i].name;
            additionalLabelHeights[planetName] = i * 11; // Set additional height
        }
    });

    console.log("Adjusted label heights for close planets:", additionalLabelHeights);
}


function createText(text, position, color, isNakshatra, index) {
    const loader = new FontLoader();
    loader.load(helvetiker, function (font) {
        const size = isNakshatra ? 5 : 25;
        const xOffset = isNakshatra ? index * 50 : 0;

        const textGeometry = new TextGeometry(text, {
            font: font,
            size: size,
            height: 0.1,
        });

        // Create a material with the specified color
        const textMaterial = new THREE.MeshBasicMaterial({ color: color }); // Specify color here

        const mesh = new THREE.Mesh(textGeometry, textMaterial);
        mesh.position.copy(position.clone().add(new THREE.Vector3(xOffset, 0, 0)));
        mesh.lookAt(camera.position);
        scene.add(mesh);
    });
}


function updateLabels() {
    for (let body in labelMeshes) {
        const textMesh = labelMeshes[body];
        if (textMesh) {
            textMesh.lookAt(camera.position);
            const planet = celestialBodies[body];
            if (planet) {
                const baseHeight = planet.mesh.geometry.parameters.radius + 20;
                const additionalHeight = additionalLabelHeights[body] || 0;
                textMesh.position.set(
                    planet.mesh.position.x,
                    planet.mesh.position.y + baseHeight + additionalHeight,
                    planet.mesh.position.z
                );
            }
        }
    }
}


let isUserInteracting = false;
let theta = 0; // Angular position around the orbit

// Function to update camera position
function updateCameraPosition() {
    // Calculate the new camera position based on 'theta'
    let thetaInRadians = theta * Math.PI / 180; // Convert degrees to radians
    camera.position.x = 785 * Math.sin(thetaInRadians);
    camera.position.y = 69;  // Set the constant Y position
    camera.position.z = 75 + 950 * Math.cos(thetaInRadians);
    camera.lookAt(scene.position); // Assuming you want to look at the center of the scene
}


// Function to handle user interaction
function handleUserInteraction() {
    isUserInteracting = true; // User is interacting, stop the rotation
    clearTimeout(resumeRotationTimeout); // Clear existing timeout
    resumeRotationTimeout = setTimeout(() => {
        isUserInteracting = false; // Resume rotation after 10 seconds of inactivity
    }, 10000); // 10 seconds delay
}

// Event listener for mouse movement
document.addEventListener('mousemove', handleUserInteraction);

// Event listener for touch events
document.addEventListener('touchstart', handleUserInteraction);

let resumeRotationTimeout;

let cameraOffset = new Vector3(0, 0, CAMERA_DISTANCE);
let attachedToPlanet = null;

function animate() {
    // Planet self rotation
    for (let body in celestialBodies) {
        celestialBodies[body].mesh.rotation.y += 0.005;
    }

    if (currentTarget) {
        camera.lookAt(currentTarget.position);
    }

    // Update label positions based on planet positions
    for (let body in celestialBodies) {
        const planet = celestialBodies[body];
        const label = labelMeshes[body];
        if (label) {
            label.position.set(0, planet.mesh.geometry.parameters.radius + 1, 0);
        }
    }

    // Update the controls
    controls.update();

    scene.traverse(function (object) {
        if (object instanceof THREE.Mesh && object.geometry instanceof TextGeometry) {
            object.lookAt(camera.position);
        }
    });

    if (currentTarget) {
        // Calculate the new camera position relative to the current target
        let newCameraPosition = currentTarget.position.clone().add(cameraOffset);
        camera.position.lerp(newCameraPosition, 0.1);  // Smooth transition to the new position
        camera.lookAt(currentTarget.position);
    }

    if (attachedToPlanet) {
        const planetMesh = celestialBodies[attachedToPlanet].mesh;
        camera.position.set(planetMesh.position.x, planetMesh.position.y, planetMesh.position.z + 5);
        controls.target = planetMesh.position;
    }

    requestAnimationFrame(animate);
    // Rotate the camera around the orbit if the user is not interacting
    if (!isUserInteracting) {
        theta += 0.1; // Adjust rotation speed as needed
        updateCameraPosition();
    }

    updateLabels();

    if (focusedPlanet) {
        camera.lookAt(focusedPlanet.mesh.position);
    }

    renderer.render(scene, camera);

    labelRenderer.render(scene, camera);
    // console.log(`Camera Position - X: ${camera.position.x}, Y: ${camera.position.y}, Z: ${camera.position.z}`);

}

let isSetupDone = false;
// Async function to setup the solar system with live data
async function setupSolarSystem() {
    if (isSetupDone) {
        // Clear existing labels if the setup has already been done
        clearLabels();
    }

    while (scene.children.length > 0) {
        scene.remove(scene.children[0]);
    }

    // Define custom distances for the orbits
    const customOrbitDistances = {
        customOrbit1: 716,
        customOrbit2: 600,
        customOrbit3: 50,
        customOrbit4: 550,
        customOrbit5: 735,
        // Add more custom orbits as needed
    };

    // Add custom orbit lines from the center to specified distances
    const orbitMaterials = new LineBasicMaterial({ color: 0xffffff, transparent: true, opacity: 0.5 });
    for (let orbitName in customOrbitDistances) {
        const distance = customOrbitDistances[orbitName];
        const ellipse = new EllipseCurve(
            0, 0, // Center at (0,0)
            distance, distance, // xRadius, yRadius
            0, 2 * Math.PI, // Start and end angles
            false, // Clockwise
            0 // Rotation
        );

        const ellipsePoints = ellipse.getPoints(100);
        const ellipseGeometry = new BufferGeometry().setFromPoints(ellipsePoints);
        const ellipseLine = new Line(ellipseGeometry, orbitMaterials);

        // Ensure the orbit line is aligned with the X-Z plane
        ellipseLine.rotation.x = Math.PI / 2;

        scene.add(ellipseLine);
    }

    const liveData = await fetchPlanetDataFromAPI(planetPositionData);
    const SCALE_FACTOR = 200;  // Adjust this value based on your visualization needs
    const SPEED_SCALE_FACTOR = 0;  // Adjust this value based on your visualization needs
    const LOG_BASE = 0.01;

    // Remove existing labels
    for (let planet in celestialBodies) {
        const bodyMesh = celestialBodies[planet].mesh;
        if (bodyMesh.userData.labelCreated) {
            const label = bodyMesh.children.find(child => child instanceof CSS2DObject);
            if (label) {
                bodyMesh.remove(label);
                bodyMesh.userData.labelCreated = false;  // Set labelCreated to false
            }
        }
    }

    // Add new labels
    for (let planet in celestialBodies) {
        const bodyMesh = celestialBodies[planet].mesh;
        if (!bodyMesh.userData.labelCreated) {
            createLabel(planet, bodyMesh);
        }
    }

    // Convert spherical coordinates (longitude, latitude, distance) to Cartesian coordinates (x, y, z)
    function sphericalToCartesian(longitude, latitude, distance) {
        let phi = (90 - latitude) * (Math.PI / 180);
        let theta = (longitude + 180) * (Math.PI / 180);

        let x = -(distance * Math.sin(phi) * Math.cos(theta));
        let z = (distance * Math.sin(phi) * Math.sin(theta));
        let y = (distance * Math.cos(phi));

        return { x, y, z };
    }

    for (let planet in liveData) {
        if (celestialBodies[planet]) {
            const actualDistance = liveData[planet].distance;  // this is the raw distance value from the API
            const scaledDistance = SCALE_FACTOR * Math.log(LOG_BASE + actualDistance);
            const position = sphericalToCartesian(liveData[planet].longitude, liveData[planet].latitude, scaledDistance);
            celestialBodies[planet].mesh.position.set(position.x, position.y, position.z);
            celestialBodies[planet].mesh.position.set(scaledDistance, 0, 0);

            // Update speed and other details
            celestialBodies[planet].speed = `${liveData[planet].speedLongitude} km/s`;
            rotationSpeeds[planet] = SPEED_SCALE_FACTOR / (1 + Math.log(LOG_BASE + actualDistance));

            // Store the position of Venus and Mars
            if (planet === 'venus') {
                venusPosition = position;
            } else if (planet === 'mars') {
                marsPosition = position;
            }
        }
    }

    // Remove labels added directly to the scene
    scene.children.forEach(child => {
        if (child instanceof CSS2DObject) {
            scene.remove(child);
        }
    });

    // Remove labels added directly to the camera
    camera.children.forEach(child => {
        if (child instanceof CSS2DObject) {
            camera.remove(child);
        }
    });

    // Now, define and add the celestial bodies to the scene
    for (let body in celestialBodies) {
        scene.add(celestialBodies[body].mesh);
    }

    setupZodiacWithPlanets()

    animate();

    isSetupDone = true;

}

setupSolarSystem();
